@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');

* {
    font-family: 'Barlow', sans-serif;
}

html, body {
    margin: 0;
}


h1, h2, h3, h4, h5, ul, li, p, span {
    margin: 0;
    margin-block: 0;
}



