

#myBlog .ql-toolbar.ql-snow + .ql-container.ql-snow {
    min-height: 400px;
}

.max-lines-5 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}



.btn-general {
    height: 30px;
    width: 100px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}


/* Login */
.login-content {
    display: flex;
    justify-content: center;
    height: 100vh;
    background-color: #FF844B;
}

.login-content div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-content div div h1 {
    text-align: center;
    letter-spacing: 3px;
    margin-bottom: 20px;
}

#login {
    width: 400px;
    height: auto;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
}

#login form label {
    margin-top: 20px;
    margin-bottom: 10px;
}

#login form input {
    margin-bottom: 10px;
    height: 30px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
}

#login form button {
    margin-top: 20px;
    margin-bottom: 15px;
    height: 40px;
    border: none;
    background-color: #FF844B;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 3px;
    color: #fff;
}


/* NavBar */
.navbar-content {
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    box-shadow: 5px 2px 5px #ccc;
    margin-bottom: 20px;
}

.navbar-content div img {
    height: 30px;
    cursor: pointer;
}

.navbar-buttons {
    display: flex;
    gap: 30px;
}

.navbar-buttons button:nth-child(1) {
    background-color: #0B5ED7;
    color: #fff;
}

.navbar-buttons button:nth-child(2) {
    background-color: #f00;
    color: #fff;
}


/* Dashboard */
.dashboard-content {
    width: 850px;
    margin: auto;
}

.dashboard-content .blog-small-container {
    background-color: #f5f5f5;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
}

.blog-small-container div h2 {
    margin-bottom: 10px;
}

.dashboard-content .blog-small-container div button:nth-child(1) {
    margin-right: 30px;
    background-color: #fe8888;
    color: #fff;
}
.dashboard-content .blog-small-container div button:nth-child(2) {
    margin-right: 30px;
    background-color: #0B5ED7;
    color: #fff;
}
.dashboard-content .blog-small-container div button:nth-child(3) {
    margin-right: 30px;
    background-color: #f00;
    color: #fff;
}

/* Blog */
.blog-edit-container {
    width: 700px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}


.blogs-loading {
    text-align: center;
    font-size: 40px;
    margin-top: 100px;
}


.blog-edit-container h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.blog-edit-container input {
    width: 91%;
    height: 40px;
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 10px;
    padding: 0 30px;
    font-size: 20px;
    font-weight: 600;
}

.blog-edit-container button {
    background-color: #0B5ED7;
    color: #fff;
}


.blog-select-content, .blog-tags-content {
    margin: 30px 0;
}

.blog-select-content label, .blog-tags-content p {
    font-size: 20px;
}

.blog-select-content select {
    width: 100%;
    font-size: 20px;
    margin-top: 10px;
    border: 1px solid #ccc;
    padding: 5px 20px;
    background-color: #f5f5f5;
    outline: none;
}

.blog-tags-content input {
    width: 94%;
    height: auto;
    font-size: 20px;
    margin-top: 10px;
    border: 1px solid #ccc;
    padding: 5px 20px;
    background-color: #f5f5f5;
    outline: none;
    border-radius: 0;
    font-weight: 100;
}

.blog-image-input-content {
    margin: 30px 0;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 20px;
}

.blog-image-input-content input {
    display: block;
    width: 96.5%;
    height: auto;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
}

.blog-img-show-content {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.blog-img-show-content img {
    max-height: 100px;
}



.show-fecha {
    text-align: center;
    width: 42px;
    position: absolute;
    background: #fff;
    padding: 10px 20px;
    font-size: 26px;
    font-weight: bold;
    bottom: 30px;
    left: 15px;
}

.blog-body-p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}

.by-and-tags {
    color: #b8b8b8;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    transition: all 200ms linear;
    transition-delay: 0.1s;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid #b8b8b8;
}

.blog-show-category {
    border-top: 1px solid #b8b8b8;
    margin-top: 50px;
    margin-bottom: 100px;
    padding-top: 20px;
}



/* Pagination */
#react-pagination ul {
    display: flex;
    gap: 20px;
    list-style: none;
    justify-content: center;
    margin: 60px 0;
}

#react-pagination ul li {
    /* width: 20px; */
    /* height: 20px; */
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    padding: 12px 0px;
    text-align: center;
    cursor: pointer;
    border: 2px solid #0B5ED7;
    font-weight: bold;
}

#react-pagination ul li a {
    padding: 15px;
    text-align: center;
}

#react-pagination ul li:hover {
    background-color: #0B5ED7;
    color: #fff;
}

#react-pagination ul .selected {
    background-color: #0B5ED7;
    color: #fff;
}


#react-pagination .previous, #react-pagination .next {
    display: none;
}

